<template>
  <div class="quotes p-8 mx-auto">
    <div class="space-y-6">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <svg
            @click="router.go(-1)"
            class="h-8 w-8 text-blue-600 hover:text-blue-700 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
            />
          </svg>
          <h1 class="text-3xl font-semibold ml-3">
            Quotes Map
          </h1>
        </div>
        <div>
          Show:
          <span
            v-if="user.claims.user_role !== 'estimator'"
            @click="hidePending = !hidePending"
            :class="{
              'bg-gray-200': hidePending,
              'bg-orange-500': !hidePending,
            }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >P<span class="xhover-target">ending</span>
          </span>
          <span
            @click="hideAssigned = !hideAssigned"
            :class="{
              'bg-gray-200': hideAssigned,
              'bg-green-500': !hideAssigned,
            }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >A<span class="xhover-target">ssigned</span>
          </span>
          <span
            @click="hideProcessed = !hideProcessed"
            :class="{
              'bg-gray-200': hideProcessed,
              'bg-blue-500': !hideProcessed,
            }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >P<span class="xhover-target">rocessed</span>
          </span>
          <span
            v-if="user.claims.user_role !== 'estimator'"
            @click="hideReviewed = !hideReviewed"
            :class="{
              'bg-gray-200': hideReviewed,
              'bg-purple-500': !hideReviewed,
            }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >R<span class="xhover-target">eviewed</span>
          </span>
          <span
            v-if="user.claims.user_role !== 'estimator'"
            @click="hideEmailed = !hideEmailed"
            :class="{ 'bg-gray-200': hideEmailed, 'bg-gray-500': !hideEmailed }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >E<span class="xhover-target">mailed</span>
          </span>
        </div>
      </div>

      <div id="map" ref="mapDiv"></div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";

export default {
  props: ["user"],
  setup(props) {
    const isLoading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const map = ref(null);
    const mapDiv = ref(null);
    const markers = [];

    const hidePending = ref(false);
    const hideAssigned = ref(false);
    const hideProcessed = ref(false);
    const hideReviewed = ref(false);
    const hideEmailed = ref(false);

    const business_id = props.user.claims.business_id ?? "";

    const { error, documents: quotes } = getCollection(
      `businesses/${business_id}/quotes`
    );

    const filteredQuotes = computed(() => {
      return quotes.value?.filter((q) => {
        if (
          props.user.claims.user_role == "estimator" &&
          q.assigned_to !== props.user.uid
        ) {
          return false;
        }
        if (
          props.user.claims.user_role == "estimator" &&
          q.quote_status != "assigned" &&
          q.quote_status != "processed"
        ) {
          return false;
        }
        // show only non-completed
        if (q.quote_status == "completed") {
          return false;
        }
        if (hidePending.value && q.quote_status == "pending") {
          return false;
        }
        if (hideAssigned.value && q.quote_status == "assigned") {
          return false;
        }
        if (hideProcessed.value && q.quote_status == "processed") {
          return false;
        }
        if (hideReviewed.value && q.quote_status == "reviewed") {
          return false;
        }
        if (hideEmailed.value && q.quote_status == "emailed") {
          return false;
        }
        return true;
      });
    });

    const pinSymbol = (type) => {
      let path, stroke;
      switch (type) {
        case "ROOFTOP":
          // pin
          path =
            "m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z";
          stroke = "#696969";
          break;
        default:
          // circle
          path =
            "M11.99,0C16.4,0 19.981,3.58 19.981,7.99C19.981,12.4 16.4,15.981 11.99,15.981C7.58,15.981 4,12.4 4,7.99C4,3.58 7.58,0 11.99,0ZM12,4C14.209,4 16,5.791 16,8C16,10.209 14.209,12 12,12C9.791,12 8,10.209 8,8C8,5.791 9.791,4 12,4Z";
          stroke = "#000000";
          break;
      }
      return {
        path: path,
        fillColor: "#ea4335",
        fillOpacity: 1,
        strokeColor: stroke,
        strokeWeight: 1,
        scale: 1.25,
      };
    };

    const clearMarkers = () => {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
    };

    const addInfoWindow = (marker, content) => {
      const infowindow = new window.google.maps.InfoWindow({
        content: content,
      });
      marker.addListener("click", () => {
        infowindow.open(map.value, marker);
      });
    };

    const initMap = () => {
      const mapOptions = {
        mapTypeId: "roadmap",
        mapTypeControl: false,
        zoom: 15,
        streetViewControl: false,
      };
      map.value = new window.google.maps.Map(mapDiv.value, mapOptions);
    };

    onMounted(() => {
      initMap();
    });

    watch(filteredQuotes, () => {
      const bounds = new window.google.maps.LatLngBounds();

      clearMarkers();

      let info = "",
        address = "";

      const statusColor = {
        pending: "text-orange-500",
        assigned: "text-green-500",
        processed: "text-blue-500",
        reviewed: "text-purple-500",
        emailed: "text-gray-500",
        accepted: "text-black",
      };

      const pins = Array.from(
        new Set(
          filteredQuotes.value.map((s) => s.location.location_coordinates.lat)
        )
      ).map((lat) => {
        return {
          lat: lat,
          long: filteredQuotes.value.find(
            (s) => s.location.location_coordinates.lat === lat
          ).location.location_coordinates.long,
          location_type: filteredQuotes.value.find(
            (s) => s.location.location_coordinates.lat === lat
          ).location.location_type,
        };
      });
      pins.forEach((pin) => {
        const coords = new window.google.maps.LatLng(
          Number(pin.lat),
          Number(pin.long)
        );

        const marker = new window.google.maps.Marker({
          map: map.value,
          position: coords,
          icon: pinSymbol(pin.location_type),
        });

        markers.push(marker);

        info = "";
        address = "";
        filteredQuotes.value.forEach((quote) => {
          if (
            pin.lat == quote.location.location_coordinates.lat &&
            pin.long == quote.location.location_coordinates.long
          ) {
            info += `<span class="${
              statusColor[quote.quote_status]
            }">&#11044;</span> <a href="/quotes/${quote.id}">Quote ${
              quote.sequence_number
            }</a><br>`;
            address = quote.location.location_address1;
          }
        });
        addInfoWindow(marker, info + address);
        bounds.extend(coords);
        map.value.fitBounds(bounds);
      });
    });

    return {
      isLoading,
      route,
      hidePending,
      hideAssigned,
      hideProcessed,
      hideReviewed,
      hideEmailed,
      router,
      error,
      mapDiv,
    };
  },
};
</script>

<style scoped>
#map {
  height: 700px;
  width: 100%;
}
</style>
